import { ObtainTokensAndStore, ExpireCookie } from './auth.service';

const Login = (username, password) => ObtainTokensAndStore(username, password);
const Logout = () => {
    ExpireCookie();

    localStorage.removeItem('authBody');

    document.location.reload();
} ;

export const UserService = {
    Login,
    Logout
};
