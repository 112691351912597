import { useState, useEffect, createContext, useContext } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import { ObtainTokensService, RefreshAccessTokenService } from '@blux/api-calls-react-helper';

const AuthContext = createContext({});

/** Obtain, Validate, and Process Tokens **/
/** Refresh Token Functions **/
const ValidateRefreshToken = () => {
    const REFRESHTOKEN = GetRefreshTokenFromCookie();

    return !!REFRESHTOKEN;
}

/** Access Token Functions **/
const ValidateAccessToken = () => {
    const accessTokenAuthBody = GetAccessTokenAuthBodyFromLocalStorage();

    return accessTokenAuthBody !== null ? ValidateAccessTokenExpDate(accessTokenAuthBody.exp) : false;
}

const GetAccessTokenAuthBodyFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem('authBody')) ?? false;
}

const ObtainAccessTokenAndStore = async (refreshToken) => {
    try {
        const response = await RefreshAccessTokenService(
            refreshToken,
            `${process.env.REACT_APP_REST_API_BASE_URL}/api/`
        );
        const responseJSON = await response.json();

        if (responseJSON.access) {
            const authAccessBody = jwt_decode(JSON.stringify(responseJSON.access));

            authAccessBody.full_token = responseJSON.access;

            localStorage.setItem("authBody", JSON.stringify(authAccessBody));

            return { status: "authorized" };
        } else {
            return responseJSON;
        }
    } catch (error) {
        console.error(error);
        return error;
    }
};

const ValidateAccessTokenExpDate = tokenExpDate =>
    tokenExpDate !== null && new Date(tokenExpDate * 1000) > new Date();


const ValidateTokens = () => ({
    validAccessToken: ValidateAccessToken(),
    validRefreshToken: ValidateRefreshToken()
});

const ObtainTokensAndStore = async (username, password) => {
    try {
        const response = await ObtainTokensService(username, password, 
            `${process.env.REACT_APP_REST_API_BASE_URL}/api/`);
        
        if (response.refresh && response.access) {
            const ACCESSTOKEN = response.access;
            const REFRESHTOKEN = response.refresh;
            const AUTHACCESSBODY = jwt_decode(JSON.stringify(ACCESSTOKEN));

            SetCookie(REFRESHTOKEN, 8);

            AUTHACCESSBODY.full_token = ACCESSTOKEN;

            localStorage.setItem("authBody", JSON.stringify(AUTHACCESSBODY));

            return { status: 'authorized' };
        } else {
            return response;
        }
    } catch (error) {
        console.error(error);
        return error;
    }
};

const ValidateAndProcessTokens = async () => {
    const TOKENS = await ValidateTokens();
    const { validAccessToken, validRefreshToken } = TOKENS;

    if (validAccessToken && validRefreshToken) {
        return true;
    } else if (!validAccessToken && validRefreshToken) {
        const REFRESHTOKEN = GetRefreshTokenFromCookie();

        try {
            const result = await ObtainAccessTokenAndStore(REFRESHTOKEN);

            if (result.status === 'authorized') {
                return true;
            }
        } catch (err) {
            console.error(`There was an error: ${err}`);

            ExpireCookie();
            localStorage.removeItem('authBody');
            return false;
        }
    }

    ExpireCookie();
    localStorage.removeItem('authBody');
    return false;
}

/** Authenticated Fetch - SFPUC Specific **/
function validateUser() {
    const NOW = (new Date()).getTime();
    const ACCESSTOKEN = GetAccessTokenAuthBodyFromLocalStorage();
    const EXPIRATION = (ACCESSTOKEN !== null) ? ACCESSTOKEN.exp : 0;
    const USERNAME = (ACCESSTOKEN !== null) ? ACCESSTOKEN.Username : null;

    if ((EXPIRATION - NOW) > 0) {
        return { isAuthenticated: true, username: USERNAME };
    } else {
        ExpireCookie();

        localStorage.removeItem('authBody');

        return { isAuthenticated: false, username: USERNAME };
    }
}

const ValidateAndGetAccessToken = (callback) => {
    ValidateAndProcessTokens().then(result => {
        if (result === true) {
            const ACCESSTOKEN = GetAccessTokenAuthBodyFromLocalStorage();

            if (ACCESSTOKEN) {
                callback(ACCESSTOKEN);
            } else {
                console.error(`An error occurred: Callback was not called.`);
            }
        } else {
            console.error(`An error occurred: Tokens are not valid.`);
        }
    });
}

/** Cookies **/
const SetCookie = (value, hours=5, name='refresh_token', domain='emnet.net', samesite='strict') => {
    const expirationDate = new Date(Date.now() + hours * 60 * 60 * 1000);

    document.cookie = `${name}=${value};expires=${expirationDate.toUTCString()};domain=${domain};path=/;samesite=${samesite}`;
}

const GetRefreshTokenFromCookie = (name = 'refresh_token') => {
    const COOKIE = document.cookie.split(';');
    const NAME = `${name}=`;

    for (let i = 0; i < COOKIE.length; i++) {
        let c = COOKIE[i];

        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(NAME) === 0) {
            return c.substring(NAME.length, c.length);
        }
    }

    return false;
}

const ExpireCookie = (name = 'refresh_token', domain = 'emnet.net', samesite = 'strict') => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; samesite=${samesite}`;
}

/** Authenticated Context API **/
/** TODO: NEEDS REFACTORING **/
const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        ValidateAndProcessTokens().then(result => {
            if (isAuthenticated !== result) {
                setIsAuthenticated(result);
            }

            if (isLoading !== false) {
                setIsLoading(false);
            }
        });
    }, [isAuthenticated, isLoading]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, isLoading, setIsAuthenticated, setIsLoading }}>
            {children}
        </AuthContext.Provider>
    )
}

/** Simple AuthenticatedRoute Service for checking user authentication **/
/** TODO: NEEDS REFACTORING **/
const AuthenticatedRoute = ({ component: Component, ...rest }) => {
    const context =  useContext(AuthContext);

    useEffect(() => {
        const RESULT = validateUser();

        // if (rest.location.pathname !== '/') {
        //     context.setLocation(rest.location.pathname);
        // }

        console.log(`AuthenticatedRoute`);
        console.log(`Context Auth: ${context.isAuthenticated} : Result Auth: ${RESULT.isAuthenticated}`);

        if (context.isAuthenticated !== RESULT.isAuthenticated) {
            context.setIsAuthenticated(RESULT);
        }
    }, [context, rest.location.pathname]);

    return (
        <Route {...rest}
            render={props =>
                context.isAuthenticated ? (<Component {...props} />) : (
                    <Redirect
                        push={true}
                        exact
                        to={{ pathname: "/login", state: {from: props.location} }}
                    />
                )
            }
        />
    );
};

export {
    AuthContext,
    AuthProvider,
    ExpireCookie,
    GetAccessTokenAuthBodyFromLocalStorage,
    GetRefreshTokenFromCookie,
    ObtainTokensAndStore,
    SetCookie,
    ValidateAccessToken,
    ValidateAndGetAccessToken,
    ValidateAndProcessTokens,
    ValidateRefreshToken,
    ValidateTokens,
    ValidateAccessTokenExpDate
}

export default withRouter(AuthenticatedRoute);
