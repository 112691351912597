import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import '@fortawesome/fontawesome-free/js/all.min.js';
import 'bulma/bulma.sass';
import 'bulma-helpers/bulma-helpers.sass';

import './assets/scss/index.scss';

const rootElement = document.getElementById('root');

ReactDOM.render(<App />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
