import { Fragment, useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { BrowserRouter as Router } from 'react-router-dom';

import { AuthContext, AuthProvider } from '../services/auth.service';
import LoginForm from './LoginForm';
import './App.scss';

// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const NOW = new Date();
const QUERYSTRING = window.location.search;
const URLPARAMS = new URLSearchParams(QUERYSTRING);
const PORTAL_BASE_URL = process.env.REACT_APP_DATA_PORTAL_BASE_URL;
const HOMEURL = `${PORTAL_BASE_URL}/dataportal/`;
const REFERRERURL = URLPARAMS.has('referrer') ? document.URL.split("?referrer=")[1] : HOMEURL;
const FOOTERTEXT = ReactHtmlParser(
    `Copyright &copy; ${NOW.getFullYear()} 
    <strong>Blu-X Intelligent Urban Water Infrastructure Platform</strong> by Xylem Vue`
);

const RouteChange = (url) => {
    window.location.href = url;
}

const App = () => {
    const [ REFERRER, SetReferrer ] = useState(null);

    useEffect(() => {
        SetReferrer(REFERRERURL);
    }, []);

    return (
        <AuthProvider>
            <Router>
                <AuthContext.Consumer>
                    {context =>
                        <Fragment>
                            {context.isAuthenticated
                                ? RouteChange(REFERRER)
                                : (
                                    <div className="app">
                                        <div className="columns is-full-height">
                                            <div className="column is-full-height">
                                                <LoginForm context={context} referrer={REFERRER} homeURL={HOMEURL} />
                                                <footer className="footer">
                                                    <div className="content has-text-centered">
                                                        {FOOTERTEXT}
                                                    </div>
                                                </footer>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </Fragment>
                    }
                </AuthContext.Consumer>
            </Router>
        </AuthProvider>
    )
}

export default App;
