import { useRef } from 'react';

import { UserService } from '../services/user.service';
import { GetAccessTokenAuthBodyFromLocalStorage, ExpireCookie } from '../services/auth.service';
import { SendUserLogging } from '@blux/user-usage-react-hook';
import blux_logo from '../assets/images/blux_logo_large_white_bg.png';

const Validate = (FORM) => {
    return FORM.current.reportValidity();
}

const CheckPortalCredentials = async (u, p, url) => {
    const CONFIG = {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `j_username=${encodeURIComponent(u)}&j_password=${encodeURIComponent(p)}`
    }

    // For localhost testing only
    // const RSP = `https://cors-anywhere.herokuapp.com/https://portal.emnet.net/dataportal/j_spring_security_check`;

    // For dataportal production only
    const RSP = `${url}j_spring_security_check`;

    try {
        const RESPONSE = await fetch(RSP, CONFIG);

        return await RESPONSE;
    } catch (err) {
        return console.error(err);
    }
}

const HandleFormSubmit = (form, context, referrer, homeURL) => {
    if (Validate(form) === false) {
        return false;
    }

    let message = document.getElementById('message');
    let username = form.current[0].value;
    let password = form.current[1].value;
    let login = new Promise((resolve) => {
        resolve(UserService.Login(username, password));
    });
    
    login.then(result => {
        if (result.status === `authorized`) {
            try {
                const ACCESSTOKENAUTHBODY = GetAccessTokenAuthBodyFromLocalStorage();

                CheckPortalCredentials(username, password, homeURL).then(response => {
                    if (typeof response !== 'undefined' && response.status === 200) {
                        if (process.env.NODE_ENV === 'production') {
                            SendUserLogging();
                        }

                        // console.log(`J_Spring Security Check Status: 200 "OK"`, response);

                        message.className = `notification is-success is-light`;
                        message.innerHTML = `Welcome! (<em>Redirecting...</em>)`;
            
                        console.log(`Login Status: ${result.status}`);
    
                        setTimeout(() => {
                            HandleAuthContext(ACCESSTOKENAUTHBODY.user_id, true, context);
                            window.location.href = referrer;
                        }, 2000);
                    } else {
                        HandleCheckPortalCredentialsErrors(message);
                    }
                });
            } catch (err) {
                console.log(err);

                HandleCheckPortalCredentialsErrors(message);
            }
        } else {
            console.log(result);

            message.className = `notification is-danger is-light`;
            message.innerHTML = `Error: <em>${result[Object.keys(result)[0]]}</em>`;
        }
    });
}

const HandleCheckPortalCredentialsErrors = (message) => {
    ExpireCookie();

    localStorage.removeItem('authBody');

    message.className = `notification is-danger is-light`;
    message.innerHTML = `Error: <em>Spring security check failed.</em><br /><br />
        Please contact EmNet at <br /><strong>(574) 855-1012</strong> if the problem persists`;
}

const HandleAuthContext = (user_id, authValue, context) => {
    if (context.isAuthenticated !== authValue) {
        context.setIsAuthenticated(authValue);
    }
}

const LoginForm = (props) => {
    const { context, referrer, homeURL } = props;
    const FORM = useRef();

    return (
        <form 
            method="POST"
            ref={FORM} 
            onSubmit={(e) => {
                e.preventDefault();

                HandleFormSubmit(FORM, context, referrer, homeURL);
            }} 
            className="is-full-height flex-column"
            // action={`${homeURL}j_spring_security_check`}
        >
            <div id="loginForm" className="card is-centered align-center justify-center">
                <div className="card-image">
                    <figure className="image has-padding-10">
                        <img id="loginLogo" src={blux_logo} alt="Blu-X Portal" />
                    </figure>
                </div>
                <div className="card-content">
                    <div className="content">
                        <div id="formBody">
                            <div className="field">
                                <label className="label" htmlFor="username">Username</label>
                                <div className="control has-icons-left">
                                    <input 
                                        className="input"
                                        id="username" 
                                        type="text" 
                                        defaultValue="" 
                                        name="j_username" 
                                        placeholder="required"
                                        autoFocus
                                        required 
                                    />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-user"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label" htmlFor="password">Password</label>
                                <div className="control has-icons-left">
                                    <input 
                                        className="input"
                                        id="password" 
                                        type="password" 
                                        defaultValue="" 
                                        name="j_password" 
                                        placeholder="required"
                                        required 
                                    />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-lock"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="field">
                                <div id="message"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="card-footer">
                    <div className="card-footer-item">
                        <div className="field is-full-width">
                            <div className="control is-full-width">
                                <button 
                                    type="submit" 
                                    className="button default is-full-width" 
                                    name="submit"
                                >
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </form>    
    );
}

export default LoginForm;